import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const AssessmentImage2 = () => {
  const data = useStaticQuery(graphql`
    query {
      assessmentImage2: file(relativePath: { eq: "assessment2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  if (!data?.assessmentImage2?.childImageSharp?.fluid) {
    return <div>Picture not found</div>
  }

  return <Img fluid={data.assessmentImage2.childImageSharp.fluid} placeholderStyle={{ visibility: "hidden" }} />
}

export default AssessmentImage2