import React from "react"
import {Container,Row,Col} from "react-bootstrap";
import Layout from "../components/layout"
import SEO from "../components/seo"
import assessmentStyle from "../styles/assessment.module.css"
import  AssessmentImage3  from "../components/imagesComponents/AssessmentImage3"
import  AssessmentImage2  from "../components/imagesComponents/AssessmentImage2"

const background = {backgroundColor:'#F8F9F9',paddingTop:'50px',paddingBottom:'50px'}

const AssessmentPage = () => (
  <Layout>
    <SEO title="Assessment" />
    <body className={assessmentStyle.font} >
    <Container style={background}>
      <Row>
        <Col sm={12} md={{span:10, offset:1}} className={assessmentStyle.boxLeft}>
          <h3 className={assessmentStyle.title} style={{color:'#E9BF3C'}}>Assessment</h3> 
          <p>
            Overwhelmed and feeling unprepared are normal reactions when faced with navigating the
            daunting process of simplifying household goods, clearing an estate, organizing a move, or
            preparing a home for sale. A free consultation to ascertain the scope of a project is focused
            on listening to client needs and home assessment. Explaining services, making
            recommendations, or giving helpful advice are some of the interactions that take place at the
            initial meeting.</p>
        </Col>
      </Row>
      <Row className="justify-content-md-center" >
            <Col sm={12} md={5}>
              <div className={assessmentStyle.boxImage1}><AssessmentImage2 /></div>
            </Col>
            <Col sm={12} md={5} className={assessmentStyle.boxRight}>
            <p>We offer thoughtful, manageable approaches tailored to the specific needs of each case. We
            use licensed and bonded contractors who are readily available. Our decades of experience in
            the real estate market and home remodeling provide essential skills in determining items to
            address in the most efficient and effective manner. Our services are geared to your goals while
            targeting the best return on investment, A bid for those services will be delivered, and upon
            acceptance a contract with an estimated timeline will initiate the project.</p>

            <p>Often there are services in the community we link to if a client is transitioning to assisted living
            or medical facilities. A project list and scheduling will relieve the anxiety and stress at a time
            where your efforts are better concentrated on well being. Good communication and honoring a
            lifetime of personal belongings keep the project on course and to the finish, with the skills to handle
            any unknowns that may arise.</p>
            <AssessmentImage3/>
            </Col>
      </Row>
    </Container>
    </body>
  </Layout>
)

export default AssessmentPage